import React from 'react'
import Img from 'gatsby-image'
import { Box } from 'rebass'

import { chunk, sum } from '../utils/array'

const PhotoGallery = ({
  images,
  itemsPerRow: itemsPerRowByBreakpoints = [1]
}) => {
  const aspectRatios = images.map((image) => image.aspectRatio)
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    (itemsPerRow) => {
      return chunk(aspectRatios, itemsPerRow).map((rowAspectRatios) => {
        return sum(rowAspectRatios)
      })
    }
  )

  return (
    <Box className='photo-gallery'>
      {images.map((image, i) => (
        <Box
          key={image.id}
          className='masonry-image'
          as={Img}
          fluid={image}
          width={rowAspectRatioSumsByBreakpoints.map(
            (rowAspectRatioSums, j) => {
              const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
              const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

              return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
            }
          )} />
      ))}
    </Box>
  )
}

export default PhotoGallery
