import React from 'react';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { graphql } from 'gatsby'

import {Layout} from '../components/index';
import PhotoGallery from '../components/PhotoGallery';
import markdownify from '../utils/markdownify';
import safePrefix from '../utils/safePrefix';

export default class Gallery extends React.Component {
    render() {
        return (
            <Layout {...this.props}>
                <section id="main" className="wrapper style1">
                    <div className="inner">
                        <header className="major">
                            <h1>{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
                            {markdownify(_.get(this.props, 'pageContext.frontmatter.subtitle'))}
                        </header>
                        {_.get(this.props, 'pageContext.frontmatter.img_path') && 
                            <span className="image main"><img src={safePrefix(_.get(this.props, 'pageContext.frontmatter.img_path'))} alt="" /></span>
                        }
                        {ReactHtmlParser(_.get(this.props, 'pageContext.html'))}
                        <div>
                          <PhotoGallery
                            images={this.images()}
                            itemsPerRow={[2, 3]} />
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }

    images = () => {
      return this.props.data.allS3ImageAsset.edges.map(({ node }) => {
        return {
          id: node.children[0].id,
          ...node.children[0].fluid
        }
      })
    }
}


export const query = graphql`
  query PhotographyPostsQuery {
    allS3ImageAsset {
    edges {
      node {
        children {
          ... on ImageSharp {
            id
            fluid {
              ...GatsbyImageSharpFluid
              originalImg
            }
          }
        }
      }
    }
  }
  }
`
