import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (
            <section id="footer" className="wrapper split style2">
                <div className="copyright">
                    <p>&copy; 2019 Michael George and Cheryl Vengren. All rights reserved.</p>
                </div>
            </section>
        );
    }
}
