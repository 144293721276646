import Layout from './Layout';
import Contact from './Contact';
import Features from './Features';
import Footer from './Footer';
import Header from './Header';
import Intro from './Intro';
import Menu from './Menu';
import Mission from './Mission';
import Social from './Social';
import Spotlight from './Spotlight';

export {
    Layout,
    Contact,
    Features,
    Footer,
    Header,
    Intro,
    Menu,
    Mission,
    Social,
    Spotlight
};

export default {
    Layout,
    Contact,
    Features,
    Footer,
    Header,
    Intro,
    Menu,
    Mission,
    Social,
    Spotlight
};
